/* global globalThis */
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { detect } from 'detect-browser';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import { MINIMUM_CHROME_VERSION } from './config/settings';

import './index.css';

// const SKIP_BROWSER_CHECK = process.env.NODE_ENV === 'development'; // when in dev, allow any browser
const SKIP_BROWSER_CHECK = true; // we want this now for the TV-in-browser feature.

// Expose mapStackTrace in case it's helpful for runtime debugging
Object.assign(globalThis, { mapStackTrace });

window.onAndroidLog = (message, level, category) => { // handle logs coming from android. This handler will get replaced once app fully loads
  console.log('onAndroidLog received before startup:', message, level, category);
};

window.onAndroidAlert = (message) => { // handle alerts coming from android. This handler will get replaced once app fully loads
  console.warn('onAndroidAlert received before startup:', message);
};

window.addEventListener('error', (evt) => {
  evt.preventDefault();
  mapStackTrace(evt.error.stack, (mappedStack) => {
    console.log([
      '[ERROR] ' + evt.error.message,
      ...mappedStack
        .replace(/^(\s*at\s+)(http.*)$/gm, '$1($2)'),
    ].join('\n'));
  });
});

const Body = lazy(() => import('./Body'));

// Use a trivial element for now for loading instead of the MUI one
const Elipsis = () => (<div>...</div>);

const Bootstrap = () => {
  if (navigator?.webdriver) {
    console.debug('Puppeteer running');
  } else if (!SKIP_BROWSER_CHECK) {
    const result = detect();
    if (result.type !== 'browser') return null;
    // There is a tricky problem currently, in that the code itself won't compile on other browsers, so the chrome check needs to be done before
    // loading the bulk of the app
    if (!['chrome','chromium-webview','edge-chromium'].includes(result?.name)) {
      return <div>{`You must use the chrome browser, version ${MINIMUM_CHROME_VERSION} or later.`}</div>;
    }
    if (Number(result.version.split('.')[0]) < MINIMUM_CHROME_VERSION) {
      return <div>{`You must use the chrome browser, version ${MINIMUM_CHROME_VERSION} or later.`}</div>;
    }
  }
  return (
    <Suspense fallback={<Elipsis />}>
      <Body />
    </Suspense>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Bootstrap />);
